@import url("https://fonts.cdnfonts.com/css/acratica");
* {
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.btn {
  background-color: #511c3a;
  padding: 7px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col-2 {
  flex-basis: 16%;
  margin: 0 0.3%;
}
.col-3 {
  flex-basis: 24%;
  margin: 0 0.45%;
}
.col-4 {
  flex-basis: 32%;
  margin: 0 0.6%;
}
.col-6 {
  flex-basis: 48%;
  margin: 0 0.9%;
}
.col-8 {
  flex-basis: 64%;
  margin: 0 1.2%;
}
.col-9 {
  flex-basis: 72%;
  margin: 0 1.3%;
}
.col-10 {
  flex-basis: 80%;
  margin: 0 1.5%;
}
/* Image magnifier */

.img-magnifier-container {
  /* width: 585px;
  height: 650px; */
  /* width: 75%;
  height: 75%; */
  margin-bottom: 15px;
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 1px solid #000;
  /* border-radius: 50%; */
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 30%;
  height: 25%;
}
.hero-content img {
  width: 100%;
}
.invite-area h3 {
  font-size: 35px;
  text-transform: uppercase;
}
.invite-area-cake {
  padding: 100px 0px;
  background-image: url("../images//landing-page/invite-are-cake-shop-background.webp");
  background-size: 100% 100%;
}
.invite-area-creation {
  padding: 0px 0px;
  padding-bottom: 115px;
  background-image: url("../images//landing-page/invite-area-creation-background.webp");
  background-size: 100% 100%;
}
.invite-area .cake-shop {
  width: 87.5%;
  margin: auto;
}
.invite-area .cake-shop .row {
  justify-content: flex-start;
}
.invite-area .cake-shop img {
  width: 100%;
  margin: auto;
}
.invite-area .cake-shop .invite-area-cake-logo {
  width: 200px;
}
.invite-area .cake-shop .description {
  text-align: center;
  margin-left: 15px;
}
.invite-area .cake-shop .description p {
  padding: 5px;
  margin: 10px;
}
.invite-area .cake-shop .description .shop-btn {
  background-color: #511c3a;
  padding: 7px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
}

.invite-area .creation {
  width: 87.5%;
  margin: auto;
  padding: 20px 0px;
}
.invite-area .creation .row {
  justify-content: flex-end;
}
.invite-area .creation img {
  width: 100%;
  margin: auto;
}
.invite-area .creation .invite-area-creation-logo {
  width: 200px;
}
.invite-area .creation .description {
  text-align: center;
  margin-right: 35px;
}
.invite-area .creation .description p {
  padding: 5px;
  margin: 10px;
}
.invite-area .creation .description .enroll-btn {
  background-color: #511c3a;
  padding: 7px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
}

.about-us {
}
.about-us-header {
  width: 100%;
}
.about-us .about-us-para {
  text-align: justify;
  width: 60%;
  margin: auto;
}
.about-us .about-us-para img {
  position: absolute;
  width: 180px;
  margin-left: -100px;
}
.about-us .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  /* margin: auto; */
  text-align: center;
}
.about-us .title .sec-1 {
  color: #511c3a;
}
.about-us .title .sec-2 {
  color: #ad4381;
}

.galary img {
  width: 100%;
}

/* Creation Page */
.creation-page {
  margin: 30px 0px;
  padding: 20px;
  background-image: url("../images/creation-background.webp");
  background-size: 100% 100%;
}
.creation-page .category-list {
  width: fit-content;
  margin: auto;
  list-style: none;
}
.creation-page .category-list-item {
  background-color: #d8adc8;
  margin: 0px 3px;
  padding: 5px 20px;
  display: inline-block;
  text-transform: uppercase;
  /* margin: 0px 5px; */
  cursor: pointer;
}
.creation-page .category-list-item:hover {
  color: black;
  background-color: white;
}
.creation-page .title {
  text-align: center;
  padding: 20px 0px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
}
.creation-page .title .sec-1 {
  color: #511c3a;
}
.creation-page .title .sec-2 {
  color: #ad4381;
}
.pagination {
  margin: auto;
}
.pagination button {
  margin: 20px 20px;
}
.product-show-case {
  width: 80%;
  margin: auto;
}
.product-show-case .row {
  justify-content: center;
}
.product-card {
  width: 240px;
  height: 300px;
  margin: 15px 5px;
}
.product-card img {
  width: 100%;
  height: 80%;
  background-color: white;
  border: 1px solid black;
  border-radius: 15px;
}
.product-card .desc {
  position: relative;
  color: black;
  padding: 15px 0px;
  background-color: white;
  z-index: 111111111;
  color: black;
  text-align: center;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 3px #e1e1e1;
  margin-top: -40px;
  margin-left: 0;
  margin-right: -3px;
}
.product-card .desc .name {
}
.product-card .desc .price {
}
/* Creation Page */

/* Product Details Page */
.product-details {
  width: 80%;
  margin: auto;
  margin-top: 40px;
}
.product-details .row {
  align-items: flex-start;
  justify-content: flex-start;
}
.image-section img {
  width: 100%;
  border: 1px solid black;
  border-radius: 15px;
  margin: 5px;
}
.product-details .description {
  margin: 30px 0px;
  padding-left: 35px;
}
.product-details .description .name {
  color: #ad4381;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}
.product-details .description .weight {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0px;
}
.product-details .description .price {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0px;
}

.product-details .description .location img {
  width: 35px;
}
.product-details .description .regular {
  padding: 0 5px;
}
.location .branch {
  align-items: center;
  margin: 10px 0px;
}
.branch p {
  font-size: 12px;
}
.branch .branch-name {
  color: #ad4381;
  font-weight: 600;
  font-size: 16px;
}
.more-section {
  background-image: url("../images/product-details-background.webp");
  background-size: 100% 100%;
  /* padding:100px 0px */
  padding-top: 50px;
  margin-bottom: 100px;
}
.details {
  width: 80%;
  margin: auto;
}
.details .title {
  color: #ad4381;
  font-size: 22px;
  margin: 50px 0px;
  text-transform: uppercase;
}
.related-products {
  width: 80%;
  margin: auto;
}
.related-products .row {
  justify-content: start;
}
.related-products .title {
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  margin: 50px 0px;
}
.related-products .title .sec-1 {
  color: #511c3a;
}
.related-products .title .sec-2 {
  color: #ad4381;
}
.related-products .product-card {
  width: 165px;
  height: 200px;
}
/* Product Details Page */
/* Custom Order Page */
.custom-order .registration-form {
  display: block;
  position: inherit;
  padding: 0px 10%;
  transform: translateX(-5%);
}
.custom-order .registration-form form {
  border: none;
  margin-top: 15px;
}
.custom-order .registration-form form .title {
  text-align: center;
  font-size: 42px;
  padding: 21px 0px;
}
.custom-order .registration-form .btn {
  margin: auto;
  width: 250px;
  padding: 20px;
}
.custom-order .registration-form img {
  width: 100px;
  display: block;
}
/* Custom Order Page */

/* Academy Page */
.academy-page .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  text-align: center;
}
.academy-page .title .sec-1 {
  color: #511c3a;
}
.academy-page .title .sec-2 {
  color: #ad4381;
}
.academy-page img {
  width: 100%;
}
.academy-page .row {
  justify-content: center;
}
.academy-page .course {
  max-width: 750px;
  margin: auto;
}
.academy-page .professional-baking-course-image {
  /* width: 50%; */
  /* margin-top: 30px; */
  width: 100%;
}

.academy-page .professional-baking-course-bg {
  position: absolute;
  margin-left: -310px;
  width: 45%;
  max-width: 450px;
  z-index: -1;
}
.academy-page .professional-baking-course {
  /* padding: 50px 10% 50px 20%; */
}
.academy-page .baking-course {
  padding: 110px 0px;
  /* padding: 125px 10% 125px 20%; */
  background-image: url("../images/invite-are-baking-background.webp");
  background-size: 100% 100%;
}
.academy-page .cookery-crackary-course {
  padding: 75px 0px;
  background-image: url("../images/cookery-crackery-course-bg.webp");
  background-size: 100% 100%;
}
.academy-page .course h3 {
  font-size: 35px;
}
.academy-page .course .description {
  margin: 20px 0px;
}
.academy-page .kids-baking-course {
  /* padding: 0px 10% 30px 20%; */
}
.academy-page .kids-baking-course .kids-image {
  margin-top: 210px;
}
.academy-page .govt-baking-course .kids-image {
  margin-top: 100px;
}
.academy-page .kids-baking-course .kids-baking-course-bdoodle {
  position: absolute;
  margin-top: -610px;
  margin-left: -81px;
  width: 55%;
  /* height: 77%; */
  z-index: -1;
}
.academy-page .kids-baking-course .kids-baking-course-bg {
  position: relative;
  margin-top: -299px;
  margin-left: -117px;
  width: 135%;
  z-index: -1;
}
.academy-page .govt-baking-course .kids-baking-course-bg {
  margin-top: -430px;
  margin-left: -117px;
  width: 156%;
}
/* Academy Page */

/* Course details Page */
.course-details .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  /* margin: auto; */
  text-align: center;
}
.course-details .title .sec-1 {
  color: #511c3a;
}
.course-details .title .sec-2 {
  color: #ad4381;
}
.course-details img {
  width: 100%;
}
.course-details img.bg {
  position: relative;
  margin-top: -347px;
  z-index: -1;
}
.course-details .container {
  width: 80%;
  margin: auto;
}
.course-details .course-title {
  font-size: 30px;
}
.course-details .objective h3 {
  margin-top: 35px;
}
.course-details .description h3 {
  margin-top: 35px;
}
.course-details .pricing {
  width: fit-content;
  background-color: #ad4381;
  color: white;
  font-size: 22px;
  padding: 50px;
}

.course-details .pricing .py-2 {
  padding: 7px 0;
}
.course-details .btn {
  margin-top: 50px;
  width: 100%;
}
.course-details .related-products {
  margin-top: -300px;
}
.course-details .pricing {
  margin-top: 30px;
  width: auto;
  text-align: center;
}
.course-card {
  position: relative;
  color: black;
  padding: 15px 0px;
  background-color: white;
  text-align: center;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 3px #e1e1e1;
}
.course-card img {
}

.course-card .name {
  height: 100px;
}
.course-card .price {
  padding: 10px 0px;
}
.course-card .btn {
  width: -webkit-fill-available;
  position: absolute;
  bottom: 10px;
}
/* Course details Page */

/* Form */
.cross-btn {
  font-weight: 900;
  float: right;
  position: relative;
  margin-top: -10px;
  cursor: default;
}
.registration-form {
  display: none;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  /* padding: 10%; */
  /* border: 1px solid black; */
}
.registration-form .success-messages {
  text-align: center;
  font-size: 22px;
  color: #83044f;
  margin-bottom: 50px;
}
.registration-form form {
  border: 1px solid black;
  padding: 20px;
  background-color: white;
  opacity: 0.96;
}
.registration-form input {
  width: 97%;
  border: 1px solid black;
  margin: 10px 0px;
  font-size: 20px;
  padding: 5px 5px;
}
.registration-form input[type="radio"] {
  width: auto;
  margin: 10px 20px;
}
.registration-form select {
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}
.registration-form textarea {
  width: 97%;
  border: 1px solid black;
  margin: 10px 0px;
  font-size: 20px;
  padding: 5px 5px;
}
/* Form */

/* Contact Page */
.contact-page {
  width: 80%;
  margin: auto;
  /* margin-top: 40px; */
}
.contact-page .registration-form form .title {
  font-size: 32px;
}
.contact-page .registration-form form .btn {
  width: -webkit-fill-available;
}
/* Contact Page */

.copyright-section {
  justify-content: space-between;
  text-align: center;
  background-color: #ad4381;
  color: white;
  font-size: 12px;
  padding: 15px 10%;
}
.copyright-section p a {
  color: #fff !important;
}
.affiliations .container {
  width: 80%;
  margin: 0 auto;
}
.affiliations .img {
  text-align: center;
  margin-bottom: 20px;
}
.affiliations .img img {
  width: 300px;
}
