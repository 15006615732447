@import url(https://fonts.cdnfonts.com/css/acratica);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  /* position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%;
  text-align: center;
}
.square {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #d71920;
  animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
  transform-origin: bottom top;
  position: relative;
}

.square:not(:last-child) {
  margin-right: 2px;
}

@keyframes loading {
  0% {
    transform: translateY(0px) scale(0.34);
    background-color: #f7d1d2;
  }
  50% {
    transform: translateY(22px) scale(1);
    background-color: #d71920;
  }
  100% {
    transform: translateY(0px) scale(0.34);
    background-color: #f7d1d2;
  }
}
.square-1 {
  animation-delay: 0.8s;
}
.square-2 {
  animation-delay: 0.7s;
}
.square-3 {
  animation-delay: 0.6s;
}
.square-4 {
  animation-delay: 0.5s;
}
.square-5 {
  animation-delay: 0.4s;
}
.square-6 {
  animation-delay: 0.3s;
}
.square-7 {
  animation-delay: 0.2s;
}

.gallery {
  width: 80%;
  margin: 20px auto;
  padding: 5px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.gallery > div {
  position: relative;
  float: left;
  padding: 5px;
}

.gallery > div > img {
  width: 263px;
  /* height: 263px; */
  transition: 0.1s transform;
  transform: translateZ(0);
  box-shadow: 0px 0px 6px 0px #a5939d;
  background-color: white;
}

.gallery > div:hover {
  z-index: 1;
}

.gallery > div:hover > img {
  transform: scale(1.8, 1.8);
  transition: 0.3s transform;
}

.cf:before,
.cf:after {
  display: table;
  content: "";
  line-height: 0;
}

.cf:after {
  clear: both;
}
@media only screen and (max-width: 480px) {
  .gallery > div > img {
    width: 182px;
    /* height: 133px; */
  }
  .gallery > div:hover > img {
    transform: scale(1, 1);
  }
}

.course-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.width {
  width: 31%;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px;
  border: 1px solid #511c3a;
}
.course-fee {
  margin: 10px 0;
}
.professional-course img {
  width: 100%;
}
.professional-course h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 6px 0;
}

* {
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.btn {
  background-color: #511c3a;
  padding: 7px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.col-2 {
  flex-basis: 16%;
  margin: 0 0.3%;
}
.col-3 {
  flex-basis: 24%;
  margin: 0 0.45%;
}
.col-4 {
  flex-basis: 32%;
  margin: 0 0.6%;
}
.col-6 {
  flex-basis: 48%;
  margin: 0 0.9%;
}
.col-8 {
  flex-basis: 64%;
  margin: 0 1.2%;
}
.col-9 {
  flex-basis: 72%;
  margin: 0 1.3%;
}
.col-10 {
  flex-basis: 80%;
  margin: 0 1.5%;
}
/* Image magnifier */

.img-magnifier-container {
  /* width: 585px;
  height: 650px; */
  /* width: 75%;
  height: 75%; */
  margin-bottom: 15px;
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 1px solid #000;
  /* border-radius: 50%; */
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 30%;
  height: 25%;
}
.hero-content img {
  width: 100%;
}
.invite-area h3 {
  font-size: 35px;
  text-transform: uppercase;
}
.invite-area-cake {
  padding: 100px 0px;
  background-image: url(/static/media/invite-are-cake-shop-background.d454551e.webp);
  background-size: 100% 100%;
}
.invite-area-creation {
  padding: 0px 0px;
  padding-bottom: 115px;
  background-image: url(/static/media/invite-area-creation-background.eb25e31c.webp);
  background-size: 100% 100%;
}
.invite-area .cake-shop {
  width: 87.5%;
  margin: auto;
}
.invite-area .cake-shop .row {
  justify-content: flex-start;
}
.invite-area .cake-shop img {
  width: 100%;
  margin: auto;
}
.invite-area .cake-shop .invite-area-cake-logo {
  width: 200px;
}
.invite-area .cake-shop .description {
  text-align: center;
  margin-left: 15px;
}
.invite-area .cake-shop .description p {
  padding: 5px;
  margin: 10px;
}
.invite-area .cake-shop .description .shop-btn {
  background-color: #511c3a;
  padding: 7px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
}

.invite-area .creation {
  width: 87.5%;
  margin: auto;
  padding: 20px 0px;
}
.invite-area .creation .row {
  justify-content: flex-end;
}
.invite-area .creation img {
  width: 100%;
  margin: auto;
}
.invite-area .creation .invite-area-creation-logo {
  width: 200px;
}
.invite-area .creation .description {
  text-align: center;
  margin-right: 35px;
}
.invite-area .creation .description p {
  padding: 5px;
  margin: 10px;
}
.invite-area .creation .description .enroll-btn {
  background-color: #511c3a;
  padding: 7px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
}

.about-us {
}
.about-us-header {
  width: 100%;
}
.about-us .about-us-para {
  text-align: justify;
  width: 60%;
  margin: auto;
}
.about-us .about-us-para img {
  position: absolute;
  width: 180px;
  margin-left: -100px;
}
.about-us .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  /* margin: auto; */
  text-align: center;
}
.about-us .title .sec-1 {
  color: #511c3a;
}
.about-us .title .sec-2 {
  color: #ad4381;
}

.galary img {
  width: 100%;
}

/* Creation Page */
.creation-page {
  margin: 30px 0px;
  padding: 20px;
  background-image: url(/static/media/creation-background.830ae9aa.webp);
  background-size: 100% 100%;
}
.creation-page .category-list {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  list-style: none;
}
.creation-page .category-list-item {
  background-color: #d8adc8;
  margin: 0px 3px;
  padding: 5px 20px;
  display: inline-block;
  text-transform: uppercase;
  /* margin: 0px 5px; */
  cursor: pointer;
}
.creation-page .category-list-item:hover {
  color: black;
  background-color: white;
}
.creation-page .title {
  text-align: center;
  padding: 20px 0px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
}
.creation-page .title .sec-1 {
  color: #511c3a;
}
.creation-page .title .sec-2 {
  color: #ad4381;
}
.pagination {
  margin: auto;
}
.pagination button {
  margin: 20px 20px;
}
.product-show-case {
  width: 80%;
  margin: auto;
}
.product-show-case .row {
  justify-content: center;
}
.product-card {
  width: 240px;
  height: 300px;
  margin: 15px 5px;
}
.product-card img {
  width: 100%;
  height: 80%;
  background-color: white;
  border: 1px solid black;
  border-radius: 15px;
}
.product-card .desc {
  position: relative;
  color: black;
  padding: 15px 0px;
  background-color: white;
  z-index: 111111111;
  color: black;
  text-align: center;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 3px #e1e1e1;
  margin-top: -40px;
  margin-left: 0;
  margin-right: -3px;
}
.product-card .desc .name {
}
.product-card .desc .price {
}
/* Creation Page */

/* Product Details Page */
.product-details {
  width: 80%;
  margin: auto;
  margin-top: 40px;
}
.product-details .row {
  align-items: flex-start;
  justify-content: flex-start;
}
.image-section img {
  width: 100%;
  border: 1px solid black;
  border-radius: 15px;
  margin: 5px;
}
.product-details .description {
  margin: 30px 0px;
  padding-left: 35px;
}
.product-details .description .name {
  color: #ad4381;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}
.product-details .description .weight {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0px;
}
.product-details .description .price {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0px;
}

.product-details .description .location img {
  width: 35px;
}
.product-details .description .regular {
  padding: 0 5px;
}
.location .branch {
  align-items: center;
  margin: 10px 0px;
}
.branch p {
  font-size: 12px;
}
.branch .branch-name {
  color: #ad4381;
  font-weight: 600;
  font-size: 16px;
}
.more-section {
  background-image: url(/static/media/product-details-background.1c742fc8.webp);
  background-size: 100% 100%;
  /* padding:100px 0px */
  padding-top: 50px;
  margin-bottom: 100px;
}
.details {
  width: 80%;
  margin: auto;
}
.details .title {
  color: #ad4381;
  font-size: 22px;
  margin: 50px 0px;
  text-transform: uppercase;
}
.related-products {
  width: 80%;
  margin: auto;
}
.related-products .row {
  justify-content: start;
}
.related-products .title {
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  margin: 50px 0px;
}
.related-products .title .sec-1 {
  color: #511c3a;
}
.related-products .title .sec-2 {
  color: #ad4381;
}
.related-products .product-card {
  width: 165px;
  height: 200px;
}
/* Product Details Page */
/* Custom Order Page */
.custom-order .registration-form {
  display: block;
  position: inherit;
  padding: 0px 10%;
  transform: translateX(-5%);
}
.custom-order .registration-form form {
  border: none;
  margin-top: 15px;
}
.custom-order .registration-form form .title {
  text-align: center;
  font-size: 42px;
  padding: 21px 0px;
}
.custom-order .registration-form .btn {
  margin: auto;
  width: 250px;
  padding: 20px;
}
.custom-order .registration-form img {
  width: 100px;
  display: block;
}
/* Custom Order Page */

/* Academy Page */
.academy-page .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  text-align: center;
}
.academy-page .title .sec-1 {
  color: #511c3a;
}
.academy-page .title .sec-2 {
  color: #ad4381;
}
.academy-page img {
  width: 100%;
}
.academy-page .row {
  justify-content: center;
}
.academy-page .course {
  max-width: 750px;
  margin: auto;
}
.academy-page .professional-baking-course-image {
  /* width: 50%; */
  /* margin-top: 30px; */
  width: 100%;
}

.academy-page .professional-baking-course-bg {
  position: absolute;
  margin-left: -310px;
  width: 45%;
  max-width: 450px;
  z-index: -1;
}
.academy-page .professional-baking-course {
  /* padding: 50px 10% 50px 20%; */
}
.academy-page .baking-course {
  padding: 110px 0px;
  /* padding: 125px 10% 125px 20%; */
  background-image: url(/static/media/invite-are-baking-background.4f40b055.webp);
  background-size: 100% 100%;
}
.academy-page .cookery-crackary-course {
  padding: 75px 0px;
  background-image: url(/static/media/cookery-crackery-course-bg.c08f6304.webp);
  background-size: 100% 100%;
}
.academy-page .course h3 {
  font-size: 35px;
}
.academy-page .course .description {
  margin: 20px 0px;
}
.academy-page .kids-baking-course {
  /* padding: 0px 10% 30px 20%; */
}
.academy-page .kids-baking-course .kids-image {
  margin-top: 210px;
}
.academy-page .govt-baking-course .kids-image {
  margin-top: 100px;
}
.academy-page .kids-baking-course .kids-baking-course-bdoodle {
  position: absolute;
  margin-top: -610px;
  margin-left: -81px;
  width: 55%;
  /* height: 77%; */
  z-index: -1;
}
.academy-page .kids-baking-course .kids-baking-course-bg {
  position: relative;
  margin-top: -299px;
  margin-left: -117px;
  width: 135%;
  z-index: -1;
}
.academy-page .govt-baking-course .kids-baking-course-bg {
  margin-top: -430px;
  margin-left: -117px;
  width: 156%;
}
/* Academy Page */

/* Course details Page */
.course-details .title {
  padding: 50px 0px;
  font-size: 48px;
  text-transform: uppercase;
  /* margin: auto; */
  text-align: center;
}
.course-details .title .sec-1 {
  color: #511c3a;
}
.course-details .title .sec-2 {
  color: #ad4381;
}
.course-details img {
  width: 100%;
}
.course-details img.bg {
  position: relative;
  margin-top: -347px;
  z-index: -1;
}
.course-details .container {
  width: 80%;
  margin: auto;
}
.course-details .course-title {
  font-size: 30px;
}
.course-details .objective h3 {
  margin-top: 35px;
}
.course-details .description h3 {
  margin-top: 35px;
}
.course-details .pricing {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ad4381;
  color: white;
  font-size: 22px;
  padding: 50px;
}

.course-details .pricing .py-2 {
  padding: 7px 0;
}
.course-details .btn {
  margin-top: 50px;
  width: 100%;
}
.course-details .related-products {
  margin-top: -300px;
}
.course-details .pricing {
  margin-top: 30px;
  width: auto;
  text-align: center;
}
.course-card {
  position: relative;
  color: black;
  padding: 15px 0px;
  background-color: white;
  text-align: center;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 3px #e1e1e1;
}
.course-card img {
}

.course-card .name {
  height: 100px;
}
.course-card .price {
  padding: 10px 0px;
}
.course-card .btn {
  width: -webkit-fill-available;
  position: absolute;
  bottom: 10px;
}
/* Course details Page */

/* Form */
.cross-btn {
  font-weight: 900;
  float: right;
  position: relative;
  margin-top: -10px;
  cursor: default;
}
.registration-form {
  display: none;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  /* padding: 10%; */
  /* border: 1px solid black; */
}
.registration-form .success-messages {
  text-align: center;
  font-size: 22px;
  color: #83044f;
  margin-bottom: 50px;
}
.registration-form form {
  border: 1px solid black;
  padding: 20px;
  background-color: white;
  opacity: 0.96;
}
.registration-form input {
  width: 97%;
  border: 1px solid black;
  margin: 10px 0px;
  font-size: 20px;
  padding: 5px 5px;
}
.registration-form input[type="radio"] {
  width: auto;
  margin: 10px 20px;
}
.registration-form select {
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
}
.registration-form textarea {
  width: 97%;
  border: 1px solid black;
  margin: 10px 0px;
  font-size: 20px;
  padding: 5px 5px;
}
/* Form */

/* Contact Page */
.contact-page {
  width: 80%;
  margin: auto;
  /* margin-top: 40px; */
}
.contact-page .registration-form form .title {
  font-size: 32px;
}
.contact-page .registration-form form .btn {
  width: -webkit-fill-available;
}
/* Contact Page */

.copyright-section {
  justify-content: space-between;
  text-align: center;
  background-color: #ad4381;
  color: white;
  font-size: 12px;
  padding: 15px 10%;
}
.copyright-section p a {
  color: #fff !important;
}
.affiliations .container {
  width: 80%;
  margin: 0 auto;
}
.affiliations .img {
  text-align: center;
  margin-bottom: 20px;
}
.affiliations .img img {
  width: 300px;
}

.nav {
  /* padding: 5px 240px; */
  padding: 10px 0px;
  background-color: #fcf0f7;
  color: black;
}
.nav .nav-bar {
  width: 83%;
  margin: auto;
}

.nav .nav-bar .logo img {
  /* width: 180px; */
  height: 85px;
}
.academy-nav .nav-bar .logo img {
  width: 75px;
}
/* .nav-item-group {
  margin-right: 100px;
} */
.nav .nav-bar ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.nav .nav-bar ul li {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
}

.footer {
  width: 80%;
  margin: 10px auto;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
}
.footer img {
  max-width: 100%;
}
.footer p {
  /* line-height: 2; */
  word-break: break-word;
}
.footer p img {
  width: 20px;
}
.footer ul {
  list-style: none;
}
.footer ul .title {
  font-weight: 700;
}
.footer ul .title:not(:first-child) {
  margin-top: 15px;
}

.footer-logo-group {
  margin-top: -50px;
}
.footer-logo-group img {
  width: 75%;
  max-width: 125px;
}
.footer-logo-group img.academy {
  width: 65%;
}
.social-icon-group {
  justify-content: start;
}
.social-icon-group img {
  width: 35px;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  .footer {
    margin-left: 10px;
  }
  .social-icon-group img {
    width: 18px;
    margin-right: 2px;
  }
  .footer .mob-col-4 {
    flex-basis: 100%;
    margin: 15px 0.6%;
  }
  .footer ul,
  p {
    font-size: 14px !important;
  }
  .footer .col-10 {
    flex-basis: 89%;
  }
  .footer .col-2 {
    flex-basis: 7%;
  }
  .footer .col-4 {
    flex-basis: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .mob-col-2 {
    flex-basis: 16%;
    margin: 0 0.3%;
  }
  .mob-col-3 {
    flex-basis: 24%;
    margin: 0 0.45%;
  }
  .mob-col-4 {
    flex-basis: 32%;
    margin: 0 0.6%;
  }
  .mob-col-6 {
    flex-basis: 48%;
    margin: 0 0.9%;
  }
  .mob-col-8 {
    flex-basis: 64%;
    margin: 0 1.2%;
  }
  .mob-col-9 {
    flex-basis: 72%;
    margin: 0 1.3%;
  }
  .mob-col-10 {
    flex-basis: 80%;
    margin: 0 1.5%;
  }
  .mob-col-12 {
    flex-basis: 100%;
  }
  .nav .nav-bar .logo img {
    width: 110px;
    height: 60px;
  }
  .about-us .about-us-para {
    width: 90%;
  }
  .about-us .title {
    padding: 33px 0px;
    padding-bottom: 15px;
    font-size: 20px;
  }
  .product-show-case .row {
    justify-content: center;
  }
  .creation-page .category-list {
    text-align: center;
  }
  .creation-page .category-list-item {
    margin: 2px;
    padding: 4px;
    font-size: 10px;
  }
  .product-card {
    width: 190px;
    height: 210px;
  }
  .product-card .desc .name {
    font-size: 12px;
  }
  .academy-page .title {
    padding: 13px 0px;
    font-size: 24px;
  }

  .academy-page .professional-baking-course {
    padding: 0px 5px;
  }
  .academy-page .course h3 {
    font-size: 12px;
  }
  .academy-page .course .description {
    font-size: 10px;
  }
  .academy-page .professional-baking-course-bg {
    margin-left: -110px;
  }
  .academy-page .baking-course {
    padding: 50px 0px;
  }
  .academy-page .kids-baking-course .kids-image {
    margin-top: 25px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bg {
    margin-left: -45px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bdoodle {
    width: 98%;
    margin-top: -350px;
    margin-left: -50px;
  }
  .more-section {
    padding-top: 0px;
    margin-top: 30px;
  }
  .product-details .description {
    width: 100%;
    margin: 3px 0px;
    padding-left: 0px;
    margin-top: 30px;
  }
  .product-details .description .location {
    margin-top: 30px;
  }
  .details .title {
    margin: 5px;
  }
  .details p {
    margin: 5px;
    text-align: justify;
  }
  .related-products {
    width: 98%;
    margin-top: 30px;
  }
  .course-details .title {
    font-size: 38px;
  }
  .course-details img.bg {
    position: absolute;
    margin-top: -410px;
  }
  .course-details .related-products {
    margin-top: 0px;
  }
  .related-products .title {
    margin: 5px 0px;
  }
  .btn {
    background-color: #511c3a;
    padding: 7px 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 25px;
  }
  .copyright-section {
    font-size: 10px;
  }
  .contact-page {
    width: 100%;
  }
  .contact-page .title {
    width: 100%;
    text-align: center;
  }
  .contact-page .company-details {
    text-align: center;
  }
  .contact-page .registration-form form .title {
    font-size: 28px;
  }
  .nav > .row {
    justify-content: center;
  }
  .nav .nav-bar ul {
    justify-content: center;
  }
  .nav .nav-bar ul li {
    margin-left: 0;
    margin-right: 8px;
    padding-right: 2px;
    /* border-right: 1px dotted #511c3a; */
  }
  .gallery > div {
    width: 100%;
  }
  .gallery > div > img {
    width: 100%;
  }
  .width {
    width: 100%;
  }
  .cake-shop .col-6,
  .col-4 {
    flex-basis: auto;
  }
  .creation .col-6,
  .col-4 {
    flex-basis: auto;
  }
  .about-us .about-us-para img {
    width: 100px;
    margin-left: 0;
  }
  .affiliations .row {
    justify-content: center;
  }
  .registration-form {
    width: 90%;
  }
  .custom-order .registration-form {
    padding: 0px 0;
    transform: translateX(6%);
  }
  .footer {
    width: 97%;
  }
}
@media (min-width: 481px) and (max-width: 768.98px) {
  .tab-col-2 {
    flex-basis: 16%;
    margin: 0 0.3%;
  }
  .tab-col-3 {
    flex-basis: 24%;
    margin: 0 0.45%;
  }
  .tab-col-4 {
    flex-basis: 32%;
    margin: 0 0.6%;
  }
  .tab-col-6 {
    flex-basis: 48%;
    margin: 0 0.9%;
  }
  .tab-col-8 {
    flex-basis: 64%;
    margin: 0 1.2%;
  }
  .tab-col-9 {
    flex-basis: 72%;
    margin: 0 1.3%;
  }
  .tab-col-10 {
    flex-basis: 80%;
    margin: 0 1.5%;
  }
  .nav .nav-bar .logo img {
    /* width: 150px; */
  }
  /* .nav-item-group {
    margin-right: 0px;
  } */
  .nav .nav-bar ul li {
    margin-left: 8px;
    font-size: 14px;
  }

  .invite-area h3 {
    font-size: 20px;
  }
  .invite-area-cake {
    padding: 30px 0px;
  }
  .invite-area .cake-shop .description {
    margin-left: 0px;
  }
  .invite-area .cake-shop .invite-area-cake-logo {
    width: 140px;
  }
  .invite-area .cake-shop .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .cake-shop .description .shop-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .invite-area-creation {
    padding-bottom: 0px;
  }

  .invite-area .creation .invite-area-creation-logo {
    width: 140px;
  }

  .invite-area .creation .description {
    margin-right: 0px;
  }
  .invite-area .creation .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .creation .description .enroll-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .about-us .about-us-para {
    width: 80%;
  }
  .about-us .title {
    padding: 30px 0px;
    font-size: 30px;
  }
  .about-us .about-us-para img {
    width: 111px;
    margin-left: -20px;
  }
  .footer .mob-col-4 {
  }

  /* Creation page */
  .creation-page {
    padding: 0;
    margin: 20px 0px;
  }
  .creation-page .title {
    padding: 5px 0px;
    font-size: 22px;
  }
  .product-show-case {
    width: 97%;
  }
  .product-card {
    width: 220px;
    height: 266px;
  }
  /* Creation page */

  /* Academy Page */
  .invite-area-baking {
    padding: 30px 0px;
  }
  .invite-area .baking .description {
    margin-left: 0px;
  }
  .invite-area .baking .invite-area-baking-logo {
    width: 100px;
  }
  .invite-area .baking .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .baking .description .baking-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .invite-area-kids-baking {
    padding-bottom: 0px;
  }

  .invite-area .kids-baking .invite-area-kids-baking-logo {
    width: 100px;
  }

  .invite-area .kids-baking .description {
    margin-right: 0px;
  }
  .invite-area .kids-baking .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .kids-baking .description .kids-baking-btn {
    font-size: 12px;
    padding: 5px 10px;
  }

  .academy-page .title {
    padding: 30px 0px;
    font-size: 37px;
  }
  .academy-page .course {
    max-width: 660px;
    padding: 0px 20px;
  }
  .academy-page .course h3 {
    font-size: 22px;
  }
  .academy-page .course .description {
    font-size: 12px;
  }
  .academy-page .baking-course {
    padding: 50px 0px;
  }
  .academy-page .kids-baking-course .kids-image {
    margin-top: 115px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bdoodle {
    width: 62%;
    margin-left: -115px;
    margin-top: -395px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bg {
    margin-left: -50px;
  }
  .academy-page .professional-baking-course-bg {
    margin-left: -150px;
  }
  /* Academy Page */

  /* Course Detals */
  .course-details .title {
    padding: 10px 0px;
    font-size: 22px;
  }
  .course-details .container {
    width: 95%;
  }
  .course-details .course-title {
    font-size: 22px;
  }
  .course-details .objective {
    font-size: 14px;
  }
  .course-details .description {
    font-size: 14px;
  }
  .course-details .pricing {
    font-size: 14px;
    padding: 30px;
  }
  .course-details .related-products {
    margin-top: -50px;
  }
  /* Course Detals */

  .footer {
    width: 100%;
  }
  .footer p {
    font-size: 9px;
    line-height: 1;
  }
  .footer ul {
    font-size: 11px;
  }
  .footer ul .title:not(:first-child) {
    margin-top: 5px;
  }
  .footer .col-4 {
    flex-basis: 24%;
  }
  .footer .col-3 {
    flex-basis: 24%;
  }
  .footer .md-col-res {
    flex-basis: 32%;
  }
  .width {
    width: 46%;
  }
  /* affiliations */
  .affiliations .row {
    flex-wrap: nowrap;
  }
}

