.footer {
  width: 80%;
  margin: 10px auto;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
}
.footer img {
  max-width: 100%;
}
.footer p {
  /* line-height: 2; */
  word-break: break-word;
}
.footer p img {
  width: 20px;
}
.footer ul {
  list-style: none;
}
.footer ul .title {
  font-weight: 700;
}
.footer ul .title:not(:first-child) {
  margin-top: 15px;
}

.footer-logo-group {
  margin-top: -50px;
}
.footer-logo-group img {
  width: 75%;
  max-width: 125px;
}
.footer-logo-group img.academy {
  width: 65%;
}
.social-icon-group {
  justify-content: start;
}
.social-icon-group img {
  width: 35px;
  margin-right: 10px;
}

@media only screen and (max-width: 480px) {
  .footer {
    margin-left: 10px;
  }
  .social-icon-group img {
    width: 18px;
    margin-right: 2px;
  }
  .footer .mob-col-4 {
    flex-basis: 100%;
    margin: 15px 0.6%;
  }
  .footer ul,
  p {
    font-size: 14px !important;
  }
  .footer .col-10 {
    flex-basis: 89%;
  }
  .footer .col-2 {
    flex-basis: 7%;
  }
  .footer .col-4 {
    flex-basis: 100%;
  }
}
