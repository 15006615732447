@media (min-width: 320px) and (max-width: 480px) {
  .mob-col-2 {
    flex-basis: 16%;
    margin: 0 0.3%;
  }
  .mob-col-3 {
    flex-basis: 24%;
    margin: 0 0.45%;
  }
  .mob-col-4 {
    flex-basis: 32%;
    margin: 0 0.6%;
  }
  .mob-col-6 {
    flex-basis: 48%;
    margin: 0 0.9%;
  }
  .mob-col-8 {
    flex-basis: 64%;
    margin: 0 1.2%;
  }
  .mob-col-9 {
    flex-basis: 72%;
    margin: 0 1.3%;
  }
  .mob-col-10 {
    flex-basis: 80%;
    margin: 0 1.5%;
  }
  .mob-col-12 {
    flex-basis: 100%;
  }
  .nav .nav-bar .logo img {
    width: 110px;
    height: 60px;
  }
  .about-us .about-us-para {
    width: 90%;
  }
  .about-us .title {
    padding: 33px 0px;
    padding-bottom: 15px;
    font-size: 20px;
  }
  .product-show-case .row {
    justify-content: center;
  }
  .creation-page .category-list {
    text-align: center;
  }
  .creation-page .category-list-item {
    margin: 2px;
    padding: 4px;
    font-size: 10px;
  }
  .product-card {
    width: 190px;
    height: 210px;
  }
  .product-card .desc .name {
    font-size: 12px;
  }
  .academy-page .title {
    padding: 13px 0px;
    font-size: 24px;
  }

  .academy-page .professional-baking-course {
    padding: 0px 5px;
  }
  .academy-page .course h3 {
    font-size: 12px;
  }
  .academy-page .course .description {
    font-size: 10px;
  }
  .academy-page .professional-baking-course-bg {
    margin-left: -110px;
  }
  .academy-page .baking-course {
    padding: 50px 0px;
  }
  .academy-page .kids-baking-course .kids-image {
    margin-top: 25px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bg {
    margin-left: -45px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bdoodle {
    width: 98%;
    margin-top: -350px;
    margin-left: -50px;
  }
  .more-section {
    padding-top: 0px;
    margin-top: 30px;
  }
  .product-details .description {
    width: 100%;
    margin: 3px 0px;
    padding-left: 0px;
    margin-top: 30px;
  }
  .product-details .description .location {
    margin-top: 30px;
  }
  .details .title {
    margin: 5px;
  }
  .details p {
    margin: 5px;
    text-align: justify;
  }
  .related-products {
    width: 98%;
    margin-top: 30px;
  }
  .course-details .title {
    font-size: 38px;
  }
  .course-details img.bg {
    position: absolute;
    margin-top: -410px;
  }
  .course-details .related-products {
    margin-top: 0px;
  }
  .related-products .title {
    margin: 5px 0px;
  }
  .btn {
    background-color: #511c3a;
    padding: 7px 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 25px;
  }
  .copyright-section {
    font-size: 10px;
  }
  .contact-page {
    width: 100%;
  }
  .contact-page .title {
    width: 100%;
    text-align: center;
  }
  .contact-page .company-details {
    text-align: center;
  }
  .contact-page .registration-form form .title {
    font-size: 28px;
  }
  .nav > .row {
    justify-content: center;
  }
  .nav .nav-bar ul {
    justify-content: center;
  }
  .nav .nav-bar ul li {
    margin-left: 0;
    margin-right: 8px;
    padding-right: 2px;
    /* border-right: 1px dotted #511c3a; */
  }
  .gallery > div {
    width: 100%;
  }
  .gallery > div > img {
    width: 100%;
  }
  .width {
    width: 100%;
  }
  .cake-shop .col-6,
  .col-4 {
    flex-basis: auto;
  }
  .creation .col-6,
  .col-4 {
    flex-basis: auto;
  }
  .about-us .about-us-para img {
    width: 100px;
    margin-left: 0;
  }
  .affiliations .row {
    justify-content: center;
  }
  .registration-form {
    width: 90%;
  }
  .custom-order .registration-form {
    padding: 0px 0;
    transform: translateX(6%);
  }
  .footer {
    width: 97%;
  }
}
@media (min-width: 481px) and (max-width: 768.98px) {
  .tab-col-2 {
    flex-basis: 16%;
    margin: 0 0.3%;
  }
  .tab-col-3 {
    flex-basis: 24%;
    margin: 0 0.45%;
  }
  .tab-col-4 {
    flex-basis: 32%;
    margin: 0 0.6%;
  }
  .tab-col-6 {
    flex-basis: 48%;
    margin: 0 0.9%;
  }
  .tab-col-8 {
    flex-basis: 64%;
    margin: 0 1.2%;
  }
  .tab-col-9 {
    flex-basis: 72%;
    margin: 0 1.3%;
  }
  .tab-col-10 {
    flex-basis: 80%;
    margin: 0 1.5%;
  }
  .nav .nav-bar .logo img {
    /* width: 150px; */
  }
  /* .nav-item-group {
    margin-right: 0px;
  } */
  .nav .nav-bar ul li {
    margin-left: 8px;
    font-size: 14px;
  }

  .invite-area h3 {
    font-size: 20px;
  }
  .invite-area-cake {
    padding: 30px 0px;
  }
  .invite-area .cake-shop .description {
    margin-left: 0px;
  }
  .invite-area .cake-shop .invite-area-cake-logo {
    width: 140px;
  }
  .invite-area .cake-shop .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .cake-shop .description .shop-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .invite-area-creation {
    padding-bottom: 0px;
  }

  .invite-area .creation .invite-area-creation-logo {
    width: 140px;
  }

  .invite-area .creation .description {
    margin-right: 0px;
  }
  .invite-area .creation .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .creation .description .enroll-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .about-us .about-us-para {
    width: 80%;
  }
  .about-us .title {
    padding: 30px 0px;
    font-size: 30px;
  }
  .about-us .about-us-para img {
    width: 111px;
    margin-left: -20px;
  }
  .footer .mob-col-4 {
  }

  /* Creation page */
  .creation-page {
    padding: 0;
    margin: 20px 0px;
  }
  .creation-page .title {
    padding: 5px 0px;
    font-size: 22px;
  }
  .product-show-case {
    width: 97%;
  }
  .product-card {
    width: 220px;
    height: 266px;
  }
  /* Creation page */

  /* Academy Page */
  .invite-area-baking {
    padding: 30px 0px;
  }
  .invite-area .baking .description {
    margin-left: 0px;
  }
  .invite-area .baking .invite-area-baking-logo {
    width: 100px;
  }
  .invite-area .baking .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .baking .description .baking-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .invite-area-kids-baking {
    padding-bottom: 0px;
  }

  .invite-area .kids-baking .invite-area-kids-baking-logo {
    width: 100px;
  }

  .invite-area .kids-baking .description {
    margin-right: 0px;
  }
  .invite-area .kids-baking .description p {
    padding: 2px;
    margin: 5px;
    font-size: 12px;
  }

  .invite-area .kids-baking .description .kids-baking-btn {
    font-size: 12px;
    padding: 5px 10px;
  }

  .academy-page .title {
    padding: 30px 0px;
    font-size: 37px;
  }
  .academy-page .course {
    max-width: 660px;
    padding: 0px 20px;
  }
  .academy-page .course h3 {
    font-size: 22px;
  }
  .academy-page .course .description {
    font-size: 12px;
  }
  .academy-page .baking-course {
    padding: 50px 0px;
  }
  .academy-page .kids-baking-course .kids-image {
    margin-top: 115px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bdoodle {
    width: 62%;
    margin-left: -115px;
    margin-top: -395px;
  }
  .academy-page .kids-baking-course .kids-baking-course-bg {
    margin-left: -50px;
  }
  .academy-page .professional-baking-course-bg {
    margin-left: -150px;
  }
  /* Academy Page */

  /* Course Detals */
  .course-details .title {
    padding: 10px 0px;
    font-size: 22px;
  }
  .course-details .container {
    width: 95%;
  }
  .course-details .course-title {
    font-size: 22px;
  }
  .course-details .objective {
    font-size: 14px;
  }
  .course-details .description {
    font-size: 14px;
  }
  .course-details .pricing {
    font-size: 14px;
    padding: 30px;
  }
  .course-details .related-products {
    margin-top: -50px;
  }
  /* Course Detals */

  .footer {
    width: 100%;
  }
  .footer p {
    font-size: 9px;
    line-height: 1;
  }
  .footer ul {
    font-size: 11px;
  }
  .footer ul .title:not(:first-child) {
    margin-top: 5px;
  }
  .footer .col-4 {
    flex-basis: 24%;
  }
  .footer .col-3 {
    flex-basis: 24%;
  }
  .footer .md-col-res {
    flex-basis: 32%;
  }
  .width {
    width: 46%;
  }
  /* affiliations */
  .affiliations .row {
    flex-wrap: nowrap;
  }
}
